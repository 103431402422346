import React, { useContext, useEffect, useState } from 'react';
import { ApolloContext } from 'react-apollo';
import gql from 'graphql-tag';
import { sortEventBricks } from '../../graphql/queries';
import Event from './Event';
// import { onCreateWall, onDeleteWall } from '../../graphql/subscriptions';

function EventWalls(props) {
  const appSyncClient = useContext(ApolloContext).client;
  const [wallsData, setWallsData] = useState([]);
  const [fetchWallsData, setFetchWallsData] = useState([]);
  const [totalWalls, setTotalWalls] = useState(0);
  // const [wallCountNew, setWallCountNew] = useState(0);
  let wallTotal = 0;
  let wallTotalNew = 0;
  // let totalWalls = 0;
  var nextToken = null,
    nextTokenFetch = null;
  var totalWidth = window.innerWidth;
  // var totalHeight = window.innerHeight;
  var totalHeight = window.innerWidth *9 / 16;
  if (totalHeight > window.innerHeight) {
    totalWidth = (window.innerHeight *16 / 9);
    totalHeight = window.innerHeight;
  }
  // const totalWidth = 600;
  // const totalHeight = 700;
  const MINUTE_MS = 15000;
  let ratio = 0, widthSize = 0, heightSize = 0;
  const addParamsToDataSet = async (dataSet, totalWalls) => {
    var area = (totalWidth * totalHeight) / totalWalls;
    if (totalHeight >= totalWidth) {
      ratio = totalHeight / totalWidth;
      heightSize = Math.sqrt(area);
      widthSize = heightSize / ratio;
    } else {
      ratio = totalWidth / totalHeight;
      widthSize = Math.sqrt(area);
      heightSize = widthSize / ratio;
    }
    var total_rows = Math.sqrt(totalWalls);
    var total_cols = Math.ceil(totalWalls / total_rows);
    total_rows = Math.floor(total_rows);
    var completeData = null;
    var width_ratio = 1600 / widthSize;
    var height_ratio = 900 / heightSize;
    var col_no = -1;
    var diffWallsRow = 0, diffWallsCols = 0;
    completeData = await dataSet.map(function (wall, index) {
      var row_no = Math.floor(index / total_cols);
      col_no++;
      if (col_no === total_cols) {
        col_no = 0;
      }
      diffWallsRow = (30 / total_rows);
      diffWallsCols = 2 * (30 / total_cols);
      if (row_no === 0) {
        diffWallsRow = 30 / total_rows;
      }
      if (row_no % 2 === 0) {
        return {
          id: wall.id,
          key: index,
          widthSize: widthSize,
          heightSize: heightSize,
          wallsinRow: total_rows,
          width_ratio: width_ratio,
          height_ratio: height_ratio,
          widthStart: col_no * widthSize + col_no * total_cols + diffWallsCols,
          heightStart: row_no * heightSize + 1 + row_no * total_cols + diffWallsRow,
        };
      } else {
        return {
          id: wall.id,
          key: index,
          widthSize: widthSize,
          heightSize: heightSize,
          wallsinRow: total_cols,
          width_ratio: width_ratio,
          height_ratio: height_ratio,
          widthStart: col_no * widthSize + widthSize / 2 + col_no * total_cols + diffWallsCols,
          heightStart: row_no * heightSize + 1 + row_no * total_cols + diffWallsRow,
        };
      }
    });
    await setWallsData((s) => [...s, ...completeData]);
  }

  const fetchData = () => {
    appSyncClient
      .query({
        query: gql(sortEventBricks),
        fetchPolicy: 'network-only',
        variables: {
          eventId: props.match.params.id,
          sortDirection: 'DESC',
          nextToken: nextToken, // token value returned by API till there is next page
        },
      })
      .then(async (response) => {
        wallTotal += response.data.sortEventBricks.items.length;
        await setTotalWalls(wallTotal);
        await setFetchWallsData((s) => [...s, ...response.data.sortEventBricks.items]);
        if (response.data.sortEventBricks.nextToken) {
          nextToken = response.data.sortEventBricks.nextToken;
          fetchData(true);
        } else {
          setFetchWallsData((s) => {
            addParamsToDataSet(s, wallTotal)
          });
        }
      });
  };

  //create wall (single line draw)
  const onCreateNewWall = async () => {
    try {
      appSyncClient
      .query({
        query: gql(sortEventBricks),
        fetchPolicy: 'network-only',
        variables: {
          eventId: props.match.params.id,
          sortDirection: 'DESC',
          nextToken: nextTokenFetch, // token value returned by API till there is next page
        },
      })
        .then(async (responsenew) => {
          wallTotalNew += responsenew.data.sortEventBricks.items.length;
          // setWallCount(wallTotal);
          if (responsenew.data.sortEventBricks.nextToken) {
            nextTokenFetch = responsenew.data.sortEventBricks.nextToken;
            onCreateNewWall();
          } else {
            nextTokenFetch = null;
          }
        });
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(async () => {
      await onCreateNewWall();
      if (wallTotalNew!==0 && wallTotalNew !==  wallTotal) {
        window.location.reload();
      }
      wallTotalNew = 0;
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [wallTotalNew]);
  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWallsData]);
  // document.body.style.backgroundImage = "Brickwall_background.jpg";
  document.body.className = 'event_wall';
  return (
    <div className="event-walls">
      {wallsData.slice(0, totalWalls).map((wall) => (
        <div
          key={wall.id}
          style={{
            marginTop: wall.heightStart + 'px',
            marginLeft: wall.widthStart + 'px',
            width: wall.widthSize + 'px',
            height: wall.heightSize + 'px',
          }}
          className="event_container"
        >
          <Event
            id={wall.id}
            data_key={wall.key}
            data_widthSize={wall.widthSize}
            data_heightSize={wall.heightSize}
            data_widthStart={wall.widthStart}
            data_heightStart={wall.heightStart}
            wallsinRow={wall.wallsinRow}
            heightRatio={wall.height_ratio}
            widthRatio={wall.width_ratio}
          />
        </div>
      ))}
    </div>
  );
}

export default EventWalls;
