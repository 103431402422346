/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    // "aws_appsync_graphqlEndpoint": "https://74uh2d2cfzdbnexjayghq7bxgi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_graphqlEndpoint": "https://wtxo46fjh5a3pnuyh3eo6nbgjm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": null,
    "aws_cognito_identity_pool_id": "eu-west-2:3ff64c00-41d7-4679-a2f0-1f55b8f8432d",
    "aws_cognito_region": "eu-west-2"
};


export default awsmobile;
