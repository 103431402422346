import React, { useContext, useEffect, useState } from 'react';
import { ApolloContext } from 'react-apollo';
import gql from 'graphql-tag';
import { sortEventBricks } from '../../graphql/queries';
import { onCreateWall, onUpdateWall } from '../../graphql/subscriptions';
import Event from './Event';

function Bricks(props) {
  const appSyncClient = useContext(ApolloContext).client;
  const [wallsData, setWallsData] = useState([]);
  const [fetchWallsData, setFetchWallsData] = useState([]);
  const [totalWalls, setTotalWalls] = useState(0);
  const maxWalls = 50;
  let wallTotal = 0;
  let wallTotalNew = 0;
  var nextToken = null,
    nextTokenFetch = null;

  const MINUTE_MS = 10000;
  var areaHeight = window.innerHeight; //set here your area height
  var areaWidth = window.innerWidth; //set here your area width
  var rectRatio = 16 / 9; //set rectangle ratio
  var gutter = [10, 10]; //set x and y spacing between rectangles
  var total_rows, total_cols, rectHeight, rectWidth; //variables that we need to calculate

  function rowIterator(iterator, N) {
    total_rows = iterator;
    total_cols = Math.ceil(N / total_rows);
    rectHeight =
      (Math.ceil(areaHeight) - (total_rows - 1) * gutter[1]) / total_rows;
    rectWidth = rectHeight * rectRatio;

    if (total_cols * rectWidth + (total_cols - 1) * gutter[0] > areaWidth) {
      rowIterator(total_rows + 1, N);
    }
  }
  function colIterator(iterator, N) {
    total_cols = iterator;
    total_rows = Math.ceil(N / total_cols);

    rectWidth = (areaWidth - (total_cols - 1) * gutter[0]) / total_cols;
    rectHeight = rectWidth / rectRatio;

    if (total_rows * rectHeight + (total_rows - 1) * gutter[1] > areaHeight) {
      colIterator(total_cols + 1, N);
    }
  }
  const addParamsToDataSet = async (dataSet, totalWalls) => {
    let widthSize, heightSize;
    if (areaWidth / 2 - 20 > areaHeight) {
      areaWidth = areaWidth - 100;
      areaHeight = areaHeight - 100;
    } else {
      if (totalWalls > 9) {
        areaWidth = areaWidth - 3.2 * (areaWidth / totalWalls);
        areaHeight = areaHeight - 2.5 * (areaHeight / totalWalls);
      } else {
        areaWidth = areaWidth - 1.5 * (areaWidth / totalWalls);
        areaHeight = areaHeight - 0.8 * (areaHeight / totalWalls);
      }
    }
    if (totalWalls <= 5) {
      // widthSize = window.innerWidth - 10;
      // heightSize = window.innerHeight / rectRatio;
      let ratio;
      var totalWidth = window.innerWidth;
      // var totalHeight = window.innerHeight;
      var totalHeight = (window.innerWidth * 9) / 16;
      if (totalHeight > window.innerHeight) {
        totalWidth = (window.innerHeight * 16) / 9;
        totalHeight = window.innerHeight;
      }
      var area = (totalWidth * totalHeight) / totalWalls;
      if (totalHeight >= totalWidth) {
        ratio = totalHeight / totalWidth;
        heightSize = Math.sqrt(area);
        widthSize = heightSize / ratio;
      } else {
        ratio = totalWidth / totalHeight;
        widthSize = Math.sqrt(area);
        heightSize = widthSize / ratio;
      }
      total_rows = Math.sqrt(totalWalls);
      total_cols = Math.ceil(totalWalls / total_rows);
      total_rows = Math.floor(total_rows);
    } else {
      rowIterator(1, totalWalls); //feed initial value
      var size1 = [rectWidth, rectHeight];
      colIterator(1, totalWalls);
      var size2 = [rectWidth, rectHeight];
      widthSize = Math.max(size1[0], size2[0]);
      heightSize = Math.max(size1[1], size2[1]);
    }
    var completeData = null;
    var width_ratio = 1600 / widthSize;
    var height_ratio = 900 / heightSize;
    var col_no = -1;
    var diffWallsRow = 0,
      diffWallsCols = 0;
    completeData = await dataSet.map(function (wall, index) {
      var row_no = Math.floor(index / total_cols);
      col_no++;
      if (col_no === total_cols) {
        col_no = 0;
      }
      diffWallsRow = 5;
      diffWallsCols = 2 * (10 / total_cols);
      if (row_no % 2 === 0) {
        return {
          id: wall.id,
          key: index,
          widthSize: widthSize,
          heightSize: heightSize,
          width_ratio: width_ratio,
          height_ratio: height_ratio,
          widthStart: col_no * widthSize + col_no * total_cols + diffWallsCols,
          heightStart:
            row_no * heightSize + 1 + row_no * total_cols + diffWallsRow,
        };
      } else {
        return {
          id: wall.id,
          key: index,
          widthSize: widthSize,
          heightSize: heightSize,
          width_ratio: width_ratio,
          height_ratio: height_ratio,
          widthStart:
            col_no * widthSize +
            widthSize / 2 +
            col_no * total_cols +
            diffWallsCols,
          heightStart:
            row_no * heightSize + 1 + row_no * total_cols + diffWallsRow,
        };
      }
    });
    await setWallsData((s) => [...s, ...completeData]);
  };

  const fetchData = () => {
    appSyncClient
      .query({
        query: gql(sortEventBricks),
        fetchPolicy: 'network-only',
        variables: {
          eventId: props.match.params.id,
          sortDirection: 'DESC',
          nextToken: nextToken, // token value returned by API till there is next page
          // limit: 50,
        },
      })
      .then(async (response) => {
        console.log('response:::::::', response)
        let responseData = response.data.sortEventBricks.items;
        await setFetchWallsData((s) => [...s, ...responseData]);
        await responseData.forEach((element) => {
          if (element.status === 'OPEN' && wallTotal < maxWalls) {
            wallTotal += 1;
          } else {
            const deleteIndex = responseData.findIndex((individualPath) => {
              return individualPath.status === 'CLOSE';
            });
            responseData = responseData.filter(
              (path, index) => index !== deleteIndex
            );
            responseData = responseData.map((path) => ({
              ...path,
            }));
            setFetchWallsData((oldData) => {
              return oldData.filter((path, index) => index !== deleteIndex);
            });
          }
        });

        await setTotalWalls(wallTotal);
        console.log('setTotalWalls', setTotalWalls(wallTotal))

        setFetchWallsData((s) => {
          addParamsToDataSet(s, wallTotal);
        });
      });
  };

  //refresh when new wall added
  // const onCreateNewWall = async () => {
  //   try {
  //     appSyncClient
  //       .query({
  //         query: gql(sortEventBricks),
  //         fetchPolicy: 'network-only',
  //         variables: {
  //           eventId: props.match.params.id,
  //           sortDirection: 'DESC',
  //           nextToken: nextTokenFetch, // token value returned by API till there is next page
  //           limit: 50,
  //         },
  //       })
  //       .then(async (responsenew) => {
  //         let responseData = responsenew.data.sortEventBricks.items;
  //         await responseData.forEach((element) => {
  //           if (element.status === 'OPEN' && wallTotalNew < maxWalls) {
  //             wallTotalNew += 1;
  //           }
  //         });
  //         if (responsenew.data.sortEventBricks.nextToken) {
  //           nextTokenFetch = responsenew.data.sortEventBricks.nextToken;
  //           onCreateNewWall();
  //         } else {
  //           nextTokenFetch = null;
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     return {};
  //   }
  // };

  //refresh when new wall added

  //   const onCreateNewWall = async () => {
  //   try {
  //     appSyncClient
  //       .subscribe({
  //         query: gql(onCreateWall),
  //         fetchPolicy: 'network-only',
  //         variables: {
  //           eventId: props.match.params.id,
  //           sortDirection: 'DESC',
  //           nextToken: nextTokenFetch, // token value returned by API till there is next page
  //           limit: 50,
  //         },
  //       })
  //       .subscribe({
  //         next: async (response) => {
  //            console.log('in function response-----', JSON.stringify(response));
  //           // let responseData = response.data.onCreateWall;
  //           // if (responseData.status === 'OPEN' && wallTotal < maxWalls) {
  //           //   wallTotalNew += 1;
  //           //   wallTotal += 1;
  //           //   setTotalWalls(wallTotal);
  //           //   await setFetchWallsData((s) => [...s, responseData]);
  //           //   await addParamsToDataSet(fetchWallsData, wallTotal);
  //           // }
  //           window.location.reload();
  //           //fetchData();
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     return {};
  //   }
  // };

  const onCreateNewWall = async () => {
    try {
      appSyncClient
        .subscribe({
          query: gql(onCreateWall),
          fetchPolicy: 'network-only',
          variables: {
            eventId: props.match.params.id,
            sortDirection: 'DESC',
            nextToken: nextTokenFetch, // token value returned by API till there is next page
            // limit: 50,
          },
        })
        .subscribe({
          next: async (response) => {
            console.log('in function response-----', JSON.stringify(response));
            // let responseData = response.data.onCreateWall;
            // if (responseData.status === 'OPEN' && wallTotal < maxWalls) {
            //   wallTotalNew += 1;
            //   wallTotal += 1;
            //   setTotalWalls(wallTotal);
            //   await setFetchWallsData((s) => [...s, responseData]);
            //   await addParamsToDataSet(fetchWallsData, wallTotal);
            // }
            if (props.match.params.id == response.data.onCreateWall.eventId)
              window.location.reload();
            //fetchData();
          },
        });
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  // refresh when wall deleted

  const onDeleteWallFunction = async () => {
    try {
      appSyncClient.subscribe({
        query: gql(onUpdateWall),
        fetchPolicy: 'network-only'
      })
        .subscribe({
          next: async (response) => {
            if (props.match.params.id == response.data.onUpdateWall.eventId)
              window.location.reload();
          },
        });
    } catch (e) {
      console.log(e);
      return {};
    }
  };
  useEffect(() => {
    fetchData();
    onCreateNewWall();
    onDeleteWallFunction();
    // const interval = setInterval(async () => {
    //   await onCreateNewWall();
    //   if (wallTotalNew !== 0 && wallTotalNew !== wallTotal) {
    //     window.location.reload();
    //   }
    //   wallTotalNew = 0;
    // }, MINUTE_MS);

    // return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [wallTotalNew]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWallsData]);
  document.body.className = 'event_wall';
  return (
    <div className="event-walls">
      {wallsData.slice(0, totalWalls).map((wall) => (
        <div
          key={wall.id}
          style={{
            marginTop: wall.heightStart + 'px',
            marginLeft: wall.widthStart + 'px',
            width: wall.widthSize + 'px',
            height: wall.heightSize + 'px',
          }}
          className="event_container"
        >
          <Event
            id={wall.id}
            data_key={wall.key}
            data_widthSize={wall.widthSize}
            data_heightSize={wall.heightSize}
            data_widthStart={wall.widthStart}
            data_heightStart={wall.heightStart}
            heightRatio={wall.height_ratio}
            widthRatio={wall.width_ratio}
          />
        </div>
      ))}
    </div>
  );
}

export default Bricks;
