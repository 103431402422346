import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import Amplify from '@aws-amplify/core';
import Client from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react';
import AppSync from './AppSync';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify } from 'aws-amplify';

import { Auth } from 'aws-amplify';
import awsmobile from '../src/aws-exports';
import * as localForage from "localforage";

Amplify.configure(awsmobile);

const client = new Client({
  url: AppSync.ApiUrl,
  region: AppSync.Region,
  auth: {
    type: AppSync.AuthMode,
    apiKey: AppSync.ApiKey,
    credentials: async () => await Auth.currentCredentials(),
  },
  offlineConfig: {
    storage: localForage,
  },
});

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <App />
    </Rehydrated>
  </ApolloProvider>
);

ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
