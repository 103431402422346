import React, {useEffect} from "react";
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom'
import "./App.css";
import Wall from "./Components/Wall/Wall";
import NewPage from "./Components/Wall/NewPage";
import Event from "./Components/Wall/Event";
import EventWalls from "./Components/Wall/EventWalls";
import EventWallsAll from "./Components/Wall/EventWallsAll";
import EventTest from "./Components/Wall/EventTest";
import EventWallsNoBkg from "./Components/Wall/EventWallsNoBkg";
import Bricks from "./Components/Wall/Bricks";



function App() {
    let deleteDrawPathSubscription=null;
    let createDrawPathSubscription=null;

    useEffect(()=>{
        return ()=>{
            if(deleteDrawPathSubscription && deleteDrawPathSubscription.unsubscribe){
                deleteDrawPathSubscription.unsubscribe();
            }
            if(createDrawPathSubscription && createDrawPathSubscription.unsubscribe){
                createDrawPathSubscription.unsubscribe();
            }
        }
    })

    return (
        <Router>
          <Switch>
            <Route exact path={"/brick-walls/:id"} component={EventWalls} />
            <Route exact path={"/event-walls/:id"} component={EventWallsNoBkg} />
            <Route exact path={"/event-test/:id"} component={EventTest} />
            <Route exact path={"/event-walls-new"} component={EventWallsAll} />
            <Route exact path={"/bricks/:id"} component={Bricks} />
            <Route exact path={"/:id"} component={Wall} />
          </Switch>
        </Router>
    );
}

export default App;
