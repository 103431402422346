const config = {
    "UserAgent": "aws-amplify-cli/0.1.0",
    "Version": "1.0",
    "IdentityManager": {
        "Default": {}
    },
    "AppSync": {
        "Default": {
          // "ApiUrl": "https://74uh2d2cfzdbnexjayghq7bxgi.appsync-api.eu-west-2.amazonaws.com/graphql",
          "ApiUrl": "https://wtxo46fjh5a3pnuyh3eo6nbgjm.appsync-api.eu-west-2.amazonaws.com/graphql",
          "Region": "eu-west-2",
          "AuthMode": "AWS_IAM",
          //"AuthMode": "API_KEY",
          "ClientDatabasePrefix": "newfeature160920",
          "identityPoolId": "eu-west-2:3ff64c00-41d7-4679-a2f0-1f55b8f8432d",
          // "ApiKey": "b2jkzqbga5cjphkvqehu6aigr4"
          "ApiKey": "ieyqu3p2czdyhhezbmbgaxlxy4"
        }
    }
}

export default {
    ...config.AppSync.Default,
};
