import React, { useContext, useEffect, useState } from 'react';
import { ApolloContext } from 'react-apollo';
import gql from 'graphql-tag';

import Canvas from './Canvas';
import { pathByTime, getWall } from '../../graphql/queries';
import {
  onCreateDrawPath,
  onDeleteDrawPath,
  onUpdateWall,
} from '../../graphql/subscriptions';
let loopCount = 1;

export default function Wall(props) {
  const appSyncClient = useContext(ApolloContext).client;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [drawCoordinates, setDrawCoordinates] = useState();
  const [completeData, setCompleteData] = useState([]);
  const [newPath, setNewPath] = useState();
  const [length, setLength] = useState(
    (drawCoordinates && drawCoordinates.length) || 0
  );
  const [drawFlag, setDrawFlag] = useState(0);
  const [flag, setFlag] = useState(true);
  const [displayImage, setDisplayImage] = useState(false);
  const [clearWall, setClearWall] = useState(false);
  const flagInitial = 1;
  var nextToken = null;

  //create wall (single line draw)
  const onCreatePath = () => {
    try {
      appSyncClient
        .subscribe({
          query: gql(onCreateDrawPath),
          fetchPolicy: 'network-only',
          variables: { WallId: props.match.params.id },
        })
        .subscribe({
          next: async (dataValue) => {
            try {
              if (drawCoordinates) {
                if (drawCoordinates.id && flagInitial) {
                  if (
                    props.match.params.id ===
                    dataValue.data.onCreateDrawPath.WallId
                  ) {
                    const item = { ...dataValue.data.onCreateDrawPath };
                    window.location.reload();

                    if (Number(item.ScreenHeight) === 10) {
                      setLength(1);
                    } else {
                      setLength(2);
                    }
                    fetchPathData();
                  }
                } else {
                  const dataValueIndex = drawCoordinates.findIndex(
                    (path) => path.id === dataValue.data.onCreateDrawPath.id
                  );
                  if (
                    dataValueIndex === -1 &&
                    props.match.params.id ===
                      dataValue.data.onCreateDrawPath.WallId
                  ) {
                    const item = { ...dataValue.data.onCreateDrawPath };
                    const newPath = {
                      mainJson: item.mainJson,
                      platform: item.platform,
                      type: item.type,
                      dpConversionFactor: item.dpConversionFactor,
                      id: item.id,
                    };
                    setNewPath(newPath);
                    setCompleteData((oldData) => {
                      const result = [...oldData, newPath];
                      return result;
                    });
                    setLength(0);
                    // await dispatch({ type: ADD_PATH, payload: newPath });
                  }
                }
              }
            } catch (e) {
              console.log(e);
              return {};
            }
          },
          error: (err) => {
            console.log('----', err);
          },
        });
    } catch (e) {
      return {};
    }
  };

  const updateScreenAfterDelete = async (dataValue, completeData) => {
    const { onDeleteDrawPath: path } = dataValue.data;
    const deleteIndex = completeData.findIndex((individualPath) => {
      return individualPath.id === path.id;
    });
    if (deleteIndex !== -1) {
      completeData = completeData.filter(
        (path, index) => index !== deleteIndex
      );
      completeData = completeData.map((path) => ({
        ...path,
        reverseData: true,
      }));
      await setCompleteData((oldData) => {
        return oldData.filter((path, index) => index !== deleteIndex);
      });
      await setDrawCoordinates(completeData);
    }
  };

  //delete wall and redraw canvas
  const onDeletePath = () => {
    setFlag(false);
    appSyncClient
      .subscribe({
        query: gql(onDeleteDrawPath),
        fetchPolicy: 'network-only',
        variables: { WallId: props.match.params.id },
      })
      .subscribe({
        next: async (dataValue) => {
          try {
            let data;
            setCompleteData((oldData) => {
              data = [...oldData];
              return oldData;
            });
            updateScreenAfterDelete(dataValue, data);
            setLength(1);
          } catch (e) {
            console.log(e);
            return {};
          }
        },
      });
  };

  //delete complete wall
  const onClearWall = () => {
    setFlag(false);
    appSyncClient
      .subscribe({
        query: gql(onUpdateWall),
        fetchPolicy: 'network-only',
        variables: { WallId: props.match.params.id },
      })
      .subscribe({
        next: async (dataValue) => {
          try {
            if (
              dataValue.data.onUpdateWall.id === props.match.params.id &&
              dataValue.data.onUpdateWall.clearAllTracker &&
              dataValue.data.onUpdateWall.clearAllTracker !== '' &&
              dataValue.data.onUpdateWall.clearAllTracker > 0
            ) {
              setLength(0);
              setClearWall(true);
              setCompleteData([]);
              // updateScreenAfterDelete(dataValue, data);
              await setDrawCoordinates([]);
            }
          } catch (e) {
            console.log(e);
            return {};
          }
        },
      });
  };

  const drawPathDataSave = async (loopCount, path, reverseData = 0) => {
    //To-do : optimize code{remove if else condition with variables}
    //if odd loop number then do not reverse data
    // when reverseData 0 set drawFlag to 1
    setDrawFlag(!!!reverseData);
    const getAllDrawCoordinates = setCoordinatesData(
      path,
      reverseData,
      loopCount
    );
    //draw data default function
    await setDrawCoordinates(getAllDrawCoordinates);
    await setCompleteData((oldData) => {
      return [...oldData, ...getAllDrawCoordinates];
    });
  };

  const setCoordinatesData = (path, reverseData, loopCount) =>
    path.items.map((item) => ({
      mainJson: item.mainJson,
      platform: item.platform,
      type: item.type,
      dpConversionFactor: item.dpConversionFactor,
      id: item.id,
      reverseData,
      loopCount,
    }));

  //loopCount to check the no. of loops and set reverse or same order to draw data (even = normal, odd = reverse draw data)
  const fetchPathData = () => {
    appSyncClient
      .query({
        query: gql(pathByTime),
        fetchPolicy: 'network-only',
        variables: {
          WallId: props.match.params.id,
          sortDirection: 'ASC',
          // limit: 100,
          nextToken: nextToken, // token value returned by API till there is next page
        },
      })
      .then(async (response) => {
        var win_width = window.innerWidth;
        var win_height = (window.innerWidth * 9) / 16;
        if (win_height > window.innerHeight) {
          win_width = (window.innerHeight * 16) / 9;
          win_height = window.innerHeight;
        }
        setDimensions({
          width: win_width, //parseFloat(path.items[0].ScreenWidth),
          height: win_height, //parseFloat(path.items[0].ScreenHeight)
        });
        /*
          true -> if response is not empty
          false -> response {}
        */
        if (response.data.pathByTime.items.length > 0) {
          // data is drawn (exists)

          const path = response.data.pathByTime;
          /*
            true -> if nextToken string exists
            false -> when nextToken null
          */
          if (response.data.pathByTime.nextToken) {
            nextToken = response.data.pathByTime.nextToken;
            await drawPathDataSave(loopCount, path, !(loopCount % 2));
            loopCount += 1;
            fetchPathData();
          } else {
            await drawPathDataSave(loopCount, path, 1);
          }
        } else {
          const getAllDrawCoordinates = {
            mainJson: null,
            platform: null,
            type: null,
            dpConversionFactor: null,
            id: null,
          };
          await setDrawCoordinates([getAllDrawCoordinates]);
        }
      });
  };

  useEffect(() => {
    fetchPathData();
    appSyncClient
      .query({
        query: gql(getWall),
        fetchPolicy: 'network-only',
        variables: {
          id: props.match.params.id,
        },
      })
      .then(async (response) => {
        // callback('err', response);
        if (
          response.data.getWall.templateUrl &&
          response.data.getWall.templateUrl !== null &&
          response.data.getWall.templateUrl !== ''
        ) {
          setDisplayImage(true);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (drawCoordinates && flag) {
      onCreatePath();
      onDeletePath();
      onClearWall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawCoordinates]);

  useEffect(() => {
    if (clearWall) {
      setClearWall(false);
    }
  }, [clearWall]);

  return (
    <>
      {true && (
        <Canvas
          paths={drawCoordinates}
          width={dimensions.width}
          height={dimensions.height}
          newPath={newPath}
          drawFlag={drawFlag}
          pathLength={length}
          clearWall={clearWall}
          displayImage={displayImage}
        />
      )}
    </>
  );
}
