import React, {useEffect, useRef, useState} from 'react';

export default function EventCanvas(props) {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [layerCtx, setLayerCtx] = useState(null);
  const { newPath } = props;

  var ratio_x = props.width_ratio;
  var ratio_y = props.height_ratio;
  const draw = (paths) => {
    if (ctx && !(paths.id)) {
      ctx.translate(0, props.data_heightSize);
      ctx.scale(1, -1);
        paths.forEach((path, i) => {
          drawLine(path);
        });
    }
  };

  function drawLine(path, delCondition = 0) {
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeLine = 'round';
    const deviceType = path.platform;
    var mainJsonData = JSON.parse(path.mainJson);
    if (path && path.type === 'DRAWING') {
      mainJsonData && mainJsonData.cords && mainJsonData.cords.forEach(({ x, y }, j, arr) => {
        ctx.lineWidth = Number(mainJsonData.strokeWidth) * Number(path.dpConversionFactor) / ratio_x;
        const brushType = mainJsonData.brushType;
        ctx.beginPath();
        if (j < arr.length - 1) {
          if (deviceType === 'ANDROID') {
            const drawY = 900 - arr[j + 1].y
            if (brushType === 'AIRBRUSH') {
              ctx.globalCompositeOperation="source-over";
              ctx.lineWidth = (Number(mainJsonData.strokeWidth) * Number(path.dpConversionFactor) / 2.8)/ratio_x;
              ctx.filter = "blur(6px)";
              ctx.strokeStyle = mainJsonData.color;
              // ctx.globalCompositeOperation = 'source-atop';
            } else if(brushType === 'ERASER') {
              ctx.globalCompositeOperation="destination-out";
              ctx.strokeStyle = "rgba(0,0,0,1)";
              // ctx.strokeStyle = "rgba(0,0,0,1)";
            } else {
              ctx.globalCompositeOperation="source-over";
              ctx.lineWidth = (Number(mainJsonData.strokeWidth) * Number(path.dpConversionFactor) / 1.1)/ratio_x;
              ctx.filter = "none";
              ctx.strokeStyle = mainJsonData.color;
              // ctx.globalCompositeOperation = 'source-atop';
            }
            
            ctx.moveTo(x/ratio_x, (900 - y)/ratio_y);
            ctx.lineTo((arr[j + 1].x)/ratio_x, drawY/ratio_y);
          } else {
             if (brushType === 'AIRBRUSH') {
              ctx.globalCompositeOperation="source-over";
              ctx.lineWidth = (Number(mainJsonData.strokeWidth) * Number(path.dpConversionFactor) / 2.8)/ratio_x;
              ctx.filter = "blur(6px)";
              ctx.strokeStyle = mainJsonData.color;
            } else if(brushType === 'ERASER') {
              ctx.globalCompositeOperation="destination-out";
              ctx.strokeStyle = "rgba(0,0,0,1)";
            } else {
              ctx.globalCompositeOperation="source-over";
              ctx.lineWidth = (Number(mainJsonData.strokeWidth) * Number(path.dpConversionFactor) / 1.1)/ratio_x;
              ctx.filter = "none";
              ctx.strokeStyle = mainJsonData.color;
            }
            ctx.moveTo(x/ratio_x, y/ratio_y);
            ctx.lineTo((arr[j + 1].x)/ratio_x, (arr[j + 1].y)/ratio_y);
          }
          
          ctx.stroke();
          // ctx.drawImage(img, x, y);
          ctx.closePath();
          ctx.restore();
        } else if (j === arr.length - 1) {
          ctx.closePath();
          ctx.restore();
        }
      });
    } else if (path && path.type === 'TEXT') {
      var message = mainJsonData.inputText;
      // var message = 'gshshs \n vshshshajakak';
      var lines = message.split('\n');
      var textLength = lines.length;
      layerCtx.save();
      layerCtx.font = mainJsonData.textSize/ratio_y+'px '+mainJsonData.fontFamily;
      // deviceType = 'ANDROID';
      layerCtx.setTransform(1, 0, 0, 1, 0, 0);
      //draw rectangle
      layerCtx.translate(mainJsonData.pivotX/ratio_x, mainJsonData.pivotY/ratio_y);
      layerCtx.rotate( (Math.PI / 180) * mainJsonData.rotation);
      // layerCtx.scale(mainJsonData.scaleFactor/ratio_x, mainJsonData.scaleFactor/ratio_y);

      // commented by lalit
      
      layerCtx.scale(mainJsonData.scaleFactor, mainJsonData.scaleFactor);
      layerCtx.translate(-mainJsonData.pivotX/ratio_x, -mainJsonData.pivotY/ratio_y);
      layerCtx.fillStyle = mainJsonData.backgroundColor;
      let x_diff_scale = 2;
      let widthText = mainJsonData.textViewWidthInDp - 30;
      console.log('mainJsonData.scaleFactor....', mainJsonData.scaleFactor);
      if (mainJsonData.scaleFactor > 1) {
        x_diff_scale = 2;
        widthText = mainJsonData.textViewWidthInDp - 35;
      }
      var max_width = (mainJsonData.pivotX - (mainJsonData.textViewWidthInDp/2) + x_diff_scale)/ratio_x;
      if (deviceType === 'ANDROID') {
        if (textLength > 1) {
          console.log('mainJsonData.scaleFactor android...', mainJsonData.scaleFactor);
          if (mainJsonData.scaleFactor <= 1) {
            console.log('<= 1');
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2)-15)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 25)/ratio_y);
          } else if (mainJsonData.scaleFactor > 1 && mainJsonData.scaleFactor < 1.1) {
            console.log('> 1 and < 1.1');
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 40)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 25)/ratio_y);
          } else {
            console.log('> 1.1');
            if (textLength == 2) {
              layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) - 30)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 20)/ratio_y);
            }
            else if (textLength == 3) {
              layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) - 5)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 20)/ratio_y);
            }
            else if (textLength == 4) {
              layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 40)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 25)/ratio_y);
            }
          }
        } else {
          // widthText = mainJsonData.textViewWidthInDp + 50;
          widthText = mainJsonData.textViewWidthInDp;
          layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 10)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 25)/ratio_y);
        }
      } else {
        // if (mainJsonData.scaleFactor > 2.4) {
        //   layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 30)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 45)/ratio_y);
        // } else {
        //   layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 15)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
        // }
        if (mainJsonData.scaleFactor > 2.4) {
          layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 15)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
        } else {
          if (textLength == 1) {
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 10)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
          }
          if (textLength == 2) {
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 8)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
          }
           if (textLength == 3) {
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 25)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
          }
           if (textLength == 4) {
            layerCtx.fillRect(max_width, (mainJsonData.pivotY - (mainJsonData.textViewHeightInDp/2) + 50)/ratio_y, widthText/ratio_x, (mainJsonData.textViewHeightInDp - 40)/ratio_y);
          }


        }
      }
      //add text
      layerCtx.fillStyle = mainJsonData.textColor;  //<======= here
      layerCtx.textAlign = "center";
      if (textLength > 1) {
        for (var i = 0; i<textLength; i++) {
          if (mainJsonData.scaleFactor > 1) {
            // if (mainJsonData.scaleFactor > 1 && mainJsonData.scaleFactor < 1.1) {
            // } else {
            if (deviceType !== 'ANDROID') {
              if (mainJsonData.scaleFactor < 2) {
                if (textLength == 2) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 30)/ratio_y);
                  }else{
                // layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 50)/ratio_y);
                layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 50)/ratio_y);
                  }
              } else {
                if (textLength == 2) {
                // layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 10*mainJsonData.scaleFactor)/ratio_y);
                layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 5*mainJsonData.scaleFactor)/ratio_y);
                }else if (textLength == 3) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 10*mainJsonData.scaleFactor)/ratio_y);
                }else if (textLength == 4) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 20*mainJsonData.scaleFactor)/ratio_y);
                }

              }
            } else {
              if (mainJsonData.scaleFactor < 2) {
                console.log('mainJsonData.scaleFactor < 2');
                layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 60)/ratio_y);
              } else {
                console.log('mainJsonData.scaleFactor > 2');
                if (textLength == 2) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 9*mainJsonData.scaleFactor)/ratio_y);
                }
                else if (textLength == 3) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 15*mainJsonData.scaleFactor)/ratio_y);
                }
                else if (textLength == 4) {
                  layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - 15*mainJsonData.scaleFactor)/ratio_y);
                }
              }
            }
            // }
            // layerCtx.fillText(lines[i], mainJsonData.pivotX, mainJsonData.pivotY + (i*mainJsonData.textSize) -15 );
          } else {
            if (deviceType === 'ANDROID') {
              if (textLength > 2) {
                layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - mainJsonData.textSize - 15)/ratio_y);
              } else {
                layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize)- 45)/ratio_y);
              }
            } else {
              // layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - mainJsonData.textSize + 10)/ratio_y );
              layerCtx.fillText(lines[i], (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY + (i*mainJsonData.textSize) - mainJsonData.textSize + 30)/ratio_y );
            }
          }
        }
      } else {
        //code commented by priti
        
        // if (mainJsonData.scaleFactor > 1) {
        //   layerCtx.fillText(message, (mainJsonData.pivotX)/ratio_x, (mainJsonData.pivotY +15 - 10*mainJsonData.scaleFactor)/ratio_y);
        // } else {
          layerCtx.fillText(message, (mainJsonData.pivotX - 6)/ratio_x, (mainJsonData.pivotY + 15)/ratio_y);
        // }
      }
      layerCtx.restore();
    }
  }

  useEffect(() => {
    var context = document.getElementById('layer1_'+props.wallId);
    var canvas_foreground = document.getElementById('canvas_foreground_'+props.wallId);
    if (context.getContext) {
      setCtx(context.getContext('2d'));
    }
    if (canvas_foreground.getContext) {
      setLayerCtx(canvas_foreground.getContext('2d'));
    }
  }, [props.wallId]);

  function removeLine(paths, reverseData = 1, delCondition = 0) {
    if (ctx && paths && !paths.id) {
      ctx.clearRect(0, 0, props.data_widthSize, props.data_heightSize);
      layerCtx.clearRect(0, 0, props.data_widthSize, props.data_heightSize);
      // ctx.setTransform(1, 0, 0, 1, 0, 0);
      paths.forEach((path, i) => {
        drawLine(path, delCondition);
      });
    }
  }
  useEffect(() => {
    if (!props.pathLength) {
        draw(props.paths)
    } else {
        props.pathLength === 2? removeLine(props.paths, 0, 1):removeLine(props.paths, 1, 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paths]);

  useEffect(() => {
    if (props.clearWall) {
      if (ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    }
  }, [ctx, layerCtx, props.clearWall])

  useEffect(() => {
    if (newPath) {
      drawLine(newPath);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPath]);

  useEffect(() => {
  }, [props.displayImage]);

  return (
    <div id={'container_'+props.wallId} className="event_container1" onClick={()=> window.open("/"+props.wallIdOpen, "_self")} style={{backgroundColor: 'white'}}>
      <canvas id={'layer1_'+props.wallId} className="layer1"
        ref={canvasRef}
        height={props.data_heightSize}
        width={props.data_widthSize}
      >
        Browser does not support canvas
      </canvas>
      <canvas id={'canvas_foreground_'+props.wallId} className="canvas_foreground"
        ref={canvasRef}
        height={props.height}
        width={props.width}
      >
        Browser does not support canvas
      </canvas>
    </div>
  );
}
