/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDrawPath = /* GraphQL */ `
  subscription OnCreateDrawPath {
    onCreateDrawPath {
      id
      WallId
      UserId
      UniqueIdentifier
      createdAt
      mainJson
      platform
      type
      dpConversionFactor
      updatedAt
    }
  }
`;
export const onUpdateDrawPath = /* GraphQL */ `
  subscription OnUpdateDrawPath {
    onUpdateDrawPath {
      id
      WallId
      UserId
      UniqueIdentifier
      createdAt
      mainJson
      platform
      type
      dpConversionFactor
      updatedAt
    }
  }
`;
export const onDeleteDrawPath = /* GraphQL */ `
  subscription OnDeleteDrawPath {
    onDeleteDrawPath {
      id
      WallId
      UserId
      UniqueIdentifier
      createdAt
      mainJson
      platform
      type
      dpConversionFactor
      updatedAt
    }
  }
`;
export const onCreateWall = /* GraphQL */ `
  subscription OnCreateWall {
    onCreateWall {
      id
      number
      name
      createdBy
      status
      createdAt
      requests {
        items {
          id
          wallID
          createdBy
          wallOwner
          Status
          wall {
            id
            number
            name
            createdBy
            status
            createdAt
            requests {
              items {
                id
                wallID
                createdBy
                wallOwner
                Status
                wall {
                  id
                  number
                  name
                  createdBy
                  status
                  createdAt
                  requests {
                    items {
                      id
                      wallID
                      createdBy
                      wallOwner
                      Status
                      wall {
                        id
                        number
                        name
                        createdBy
                        status
                        createdAt
                        requests {
                          items {
                            id
                            wallID
                            createdBy
                            wallOwner
                            Status
                            wall {
                              id
                              number
                              name
                              createdBy
                              status
                              createdAt
                              requests {
                                nextToken
                              }
                              updatedAt
                              templateUrl
                              clearAllTracker
                              eventId
                            }
                            createdAt
                            updatedAt
                          }
                          nextToken
                        }
                        updatedAt
                        templateUrl
                        clearAllTracker
                        eventId
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  updatedAt
                  templateUrl
                  clearAllTracker
                  eventId
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            updatedAt
            templateUrl
            clearAllTracker
            eventId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      templateUrl
      clearAllTracker
      eventId
    }
  }
`;
export const onUpdateWall = /* GraphQL */ `
  subscription OnUpdateWall {
    onUpdateWall {
      id
      number
      name
      createdBy
      status
      createdAt
      requests {
        items {
          id
          wallID
          createdBy
          wallOwner
          Status
          wall {
            id
            number
            name
            createdBy
            status
            createdAt
            requests {
              items {
                id
                wallID
                createdBy
                wallOwner
                Status
                wall {
                  id
                  number
                  name
                  createdBy
                  status
                  createdAt
                  requests {
                    items {
                      id
                      wallID
                      createdBy
                      wallOwner
                      Status
                      wall {
                        id
                        number
                        name
                        createdBy
                        status
                        createdAt
                        requests {
                          items {
                            id
                            wallID
                            createdBy
                            wallOwner
                            Status
                            wall {
                              id
                              number
                              name
                              createdBy
                              status
                              createdAt
                              requests {
                                nextToken
                              }
                              updatedAt
                              templateUrl
                              clearAllTracker
                              eventId
                            }
                            createdAt
                            updatedAt
                          }
                          nextToken
                        }
                        updatedAt
                        templateUrl
                        clearAllTracker
                        eventId
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  updatedAt
                  templateUrl
                  clearAllTracker
                  eventId
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            updatedAt
            templateUrl
            clearAllTracker
            eventId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      templateUrl
      clearAllTracker
      eventId
    }
  }
`;
export const onDeleteWall = /* GraphQL */ `
  subscription OnDeleteWall {
    onDeleteWall {
      id
      number
      name
      createdBy
      status
      createdAt
      requests {
        items {
          id
          wallID
          createdBy
          wallOwner
          Status
          wall {
            id
            number
            name
            createdBy
            status
            createdAt
            requests {
              items {
                id
                wallID
                createdBy
                wallOwner
                Status
                wall {
                  id
                  number
                  name
                  createdBy
                  status
                  createdAt
                  requests {
                    items {
                      id
                      wallID
                      createdBy
                      wallOwner
                      Status
                      wall {
                        id
                        number
                        name
                        createdBy
                        status
                        createdAt
                        requests {
                          items {
                            id
                            wallID
                            createdBy
                            wallOwner
                            Status
                            wall {
                              id
                              number
                              name
                              createdBy
                              status
                              createdAt
                              requests {
                                nextToken
                              }
                              updatedAt
                              templateUrl
                              clearAllTracker
                              eventId
                            }
                            createdAt
                            updatedAt
                          }
                          nextToken
                        }
                        updatedAt
                        templateUrl
                        clearAllTracker
                        eventId
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  updatedAt
                  templateUrl
                  clearAllTracker
                  eventId
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            updatedAt
            templateUrl
            clearAllTracker
            eventId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      templateUrl
      clearAllTracker
      eventId
    }
  }
`;
export const onCreateWallRequest = /* GraphQL */ `
  subscription OnCreateWallRequest {
    onCreateWallRequest {
      id
      wallID
      createdBy
      wallOwner
      Status
      wall {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWallRequest = /* GraphQL */ `
  subscription OnUpdateWallRequest {
    onUpdateWallRequest {
      id
      wallID
      createdBy
      wallOwner
      Status
      wall {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWallRequest = /* GraphQL */ `
  subscription OnDeleteWallRequest {
    onDeleteWallRequest {
      id
      wallID
      createdBy
      wallOwner
      Status
      wall {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      createdAt
      updatedAt
    }
  }
`;

