/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDrawPath = /* GraphQL */ `
  query GetDrawPath($id: ID!) {
    getDrawPath(id: $id) {
      id
      WallId
      UserId
      UniqueIdentifier
      createdAt
      mainJson
      platform
      type
      dpConversionFactor
      updatedAt
    }
  }
`;
export const listDrawPaths = /* GraphQL */ `
  query ListDrawPaths(
    $filter: ModelDrawPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrawPaths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        WallId
        UserId
        UniqueIdentifier
        createdAt
        mainJson
        platform
        type
        dpConversionFactor
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWall = /* GraphQL */ `
  query GetWall($id: ID!) {
    getWall(id: $id) {
      id
      number
      name
      createdBy
      status
      createdAt
      requests {
        items {
          id
          wallID
          createdBy
          wallOwner
          Status
          wall {
            id
            number
            name
            createdBy
            status
            createdAt
            requests {
              items {
                id
                wallID
                createdBy
                wallOwner
                Status
                wall {
                  id
                  number
                  name
                  createdBy
                  status
                  createdAt
                  requests {
                    items {
                      id
                      wallID
                      createdBy
                      wallOwner
                      Status
                      wall {
                        id
                        number
                        name
                        createdBy
                        status
                        createdAt
                        requests {
                          items {
                            id
                            wallID
                            createdBy
                            wallOwner
                            Status
                            wall {
                              id
                              number
                              name
                              createdBy
                              status
                              createdAt
                              requests {
                                nextToken
                              }
                              updatedAt
                              templateUrl
                              clearAllTracker
                              eventId
                            }
                            createdAt
                            updatedAt
                          }
                          nextToken
                        }
                        updatedAt
                        templateUrl
                        clearAllTracker
                        eventId
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  updatedAt
                  templateUrl
                  clearAllTracker
                  eventId
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            updatedAt
            templateUrl
            clearAllTracker
            eventId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      templateUrl
      clearAllTracker
      eventId
    }
  }
`;
export const listWalls = /* GraphQL */ `
  query ListWalls(
    $filter: ModelWallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      nextToken
    }
  }
`;
export const getWallRequest = /* GraphQL */ `
  query GetWallRequest($id: ID!) {
    getWallRequest(id: $id) {
      id
      wallID
      createdBy
      wallOwner
      Status
      wall {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWallRequests = /* GraphQL */ `
  query ListWallRequests(
    $filter: ModelWallRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWallRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        wallID
        createdBy
        wallOwner
        Status
        wall {
          id
          number
          name
          createdBy
          status
          createdAt
          requests {
            items {
              id
              wallID
              createdBy
              wallOwner
              Status
              wall {
                id
                number
                name
                createdBy
                status
                createdAt
                requests {
                  items {
                    id
                    wallID
                    createdBy
                    wallOwner
                    Status
                    wall {
                      id
                      number
                      name
                      createdBy
                      status
                      createdAt
                      requests {
                        items {
                          id
                          wallID
                          createdBy
                          wallOwner
                          Status
                          wall {
                            id
                            number
                            name
                            createdBy
                            status
                            createdAt
                            requests {
                              items {
                                id
                                wallID
                                createdBy
                                wallOwner
                                Status
                                createdAt
                                updatedAt
                              }
                              nextToken
                            }
                            updatedAt
                            templateUrl
                            clearAllTracker
                            eventId
                          }
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                      updatedAt
                      templateUrl
                      clearAllTracker
                      eventId
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                updatedAt
                templateUrl
                clearAllTracker
                eventId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
          templateUrl
          clearAllTracker
          eventId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pathByTime = /* GraphQL */ `
  query PathByTime(
    $WallId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDrawPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pathByTime(
      WallId: $WallId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        WallId
        UserId
        UniqueIdentifier
        createdAt
        mainJson
        platform
        type
        dpConversionFactor
        updatedAt
      }
      nextToken
    }
  }
`;
export const sortEventBricks = /* GraphQL */ `
  query SortEventBricks(
    $eventId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sortEventBricks(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      nextToken
    }
  }
`;
export const sortEventBricksNew = /* GraphQL */ `
  query SortEventBricksNew(
    $eventId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sortEventBricksNew(
      eventId: $eventId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        createdBy
        status
        createdAt
        requests {
          items {
            id
            wallID
            createdBy
            wallOwner
            Status
            wall {
              id
              number
              name
              createdBy
              status
              createdAt
              requests {
                items {
                  id
                  wallID
                  createdBy
                  wallOwner
                  Status
                  wall {
                    id
                    number
                    name
                    createdBy
                    status
                    createdAt
                    requests {
                      items {
                        id
                        wallID
                        createdBy
                        wallOwner
                        Status
                        wall {
                          id
                          number
                          name
                          createdBy
                          status
                          createdAt
                          requests {
                            items {
                              id
                              wallID
                              createdBy
                              wallOwner
                              Status
                              wall {
                                id
                                number
                                name
                                createdBy
                                status
                                createdAt
                                updatedAt
                                templateUrl
                                clearAllTracker
                                eventId
                              }
                              createdAt
                              updatedAt
                            }
                            nextToken
                          }
                          updatedAt
                          templateUrl
                          clearAllTracker
                          eventId
                        }
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    updatedAt
                    templateUrl
                    clearAllTracker
                    eventId
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              templateUrl
              clearAllTracker
              eventId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
        templateUrl
        clearAllTracker
        eventId
      }
      nextToken
    }
  }
`;
export const myWalls = /* GraphQL */ `
  query MyWalls(
    $createdBy: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWallRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MyWalls(
      createdBy: $createdBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wallID
        createdBy
        wallOwner
        Status
        wall {
          id
          number
          name
          createdBy
          status
          createdAt
          requests {
            items {
              id
              wallID
              createdBy
              wallOwner
              Status
              wall {
                id
                number
                name
                createdBy
                status
                createdAt
                requests {
                  items {
                    id
                    wallID
                    createdBy
                    wallOwner
                    Status
                    wall {
                      id
                      number
                      name
                      createdBy
                      status
                      createdAt
                      requests {
                        items {
                          id
                          wallID
                          createdBy
                          wallOwner
                          Status
                          wall {
                            id
                            number
                            name
                            createdBy
                            status
                            createdAt
                            requests {
                              items {
                                id
                                wallID
                                createdBy
                                wallOwner
                                Status
                                createdAt
                                updatedAt
                              }
                              nextToken
                            }
                            updatedAt
                            templateUrl
                            clearAllTracker
                            eventId
                          }
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                      updatedAt
                      templateUrl
                      clearAllTracker
                      eventId
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                updatedAt
                templateUrl
                clearAllTracker
                eventId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
          templateUrl
          clearAllTracker
          eventId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const myRequests = /* GraphQL */ `
  query MyRequests(
    $wallOwner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWallRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MyRequests(
      wallOwner: $wallOwner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wallID
        createdBy
        wallOwner
        Status
        wall {
          id
          number
          name
          createdBy
          status
          createdAt
          requests {
            items {
              id
              wallID
              createdBy
              wallOwner
              Status
              wall {
                id
                number
                name
                createdBy
                status
                createdAt
                requests {
                  items {
                    id
                    wallID
                    createdBy
                    wallOwner
                    Status
                    wall {
                      id
                      number
                      name
                      createdBy
                      status
                      createdAt
                      requests {
                        items {
                          id
                          wallID
                          createdBy
                          wallOwner
                          Status
                          wall {
                            id
                            number
                            name
                            createdBy
                            status
                            createdAt
                            requests {
                              items {
                                id
                                wallID
                                createdBy
                                wallOwner
                                Status
                                createdAt
                                updatedAt
                              }
                              nextToken
                            }
                            updatedAt
                            templateUrl
                            clearAllTracker
                            eventId
                          }
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                      updatedAt
                      templateUrl
                      clearAllTracker
                      eventId
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                updatedAt
                templateUrl
                clearAllTracker
                eventId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
          templateUrl
          clearAllTracker
          eventId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
